
<template>
  <v-card>
    <v-card-title>
      Rewards
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
      <v-row>
        <v-col cols="6">
          <v-menu
            v-model="startDateCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.startDate"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.startDate" @input="startDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6">
          <v-menu
            v-model="endDateCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filters.endDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filters.endDate" @input="endDateCalendar = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>


      <v-btn color="primary" @click="loadRewards">Load</v-btn>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="rewards" 
      class="elevation-1"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/reward/${item._id}/edit`, params: item })">edit</v-icon>
        <!-- <v-icon small class="mr-2" @click="deleteReward(item._id)">delete</v-icon> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Key", value: "key", sortable: true },
        { text: "Description", value: "description", sortable: true },
        { text: "Points", value: "points", sortable: true },
        { text: "Date Created", value: "createdAt", sortable: true },
        { text: "UserID", value: "userId", sortable: false },
        { text: "Action", value: "actions", sortable: false }
      ],
      rewards: [],
      filters: {},
      startDateCalendar: false,
      endDateCalendar: false
    };
  },

  mounted() {
    this.loadRewards({ limit: 100 });
  },

  methods: {
    loadRewards(filters) {
      this.$setLoader();
      service.fetchRewards(filters.limit ? filters : this.filters).then(r => {
        this.rewards = r.data;
        this.$disableLoader();
      });
    },

    deleteReward(rewardId) {
      if (confirm("Are you sure you want to delete this reward?")) {
        service.deleteReward(rewardId).then(res => {
          this.$showSuccessMessage(res.data)
          this.loadRewards();
        })
      } 
    },
  }
};
</script>
